<template>
  <div>
    <van-form ref="form">
      <van-cell-group>
        <van-field v-model="form.stuName" readonly label="姓名" />
        <van-field v-model="form.stuNo" readonly label="学号" />
        <van-field v-model="form.collName" readonly label="院系" />
        <van-field v-model="form.sqecName" readonly label="专业" />
        <custom-select required ref="class" :value="form.classNo" labelKey="className" valueKey="classNo" placeholder="请选择班级" label="班级" :columns="classInfos" />
      </van-cell-group>
    </van-form>
    <div class="submit-button shadow-style">
      <van-button round block type="primary" :loading="loading" native-type="submit" @click="doSubmit">保 存</van-button>
    </div>
  </div>
</template>

<script>
import { settingClassInfo, getStuinfo } from '@/api/modules/xj/stuInfo'
import commonOption from '@/api/common/common'
import { validCustom } from "@/utils";
import { Dialog } from "vant";


export default {
  name: "ClassSetting",
  data() {
    return {
      form: {
        id: null,
        stuName: null,
        stuNo: null,
        collNo: null,
        sqecCode: null,
        classNo: null,
        collName: null,
        sqecName: null,
        className: null
      },
      refsh: true,
      classInfos: [],
      loading: false,
      customRuleFileds: ['class']
    }
  },
  async created() {
    let stuNo = this.$route.query.stuNo

    await getStuinfo({ stuNo: stuNo }).then((res) => {
      this.form = {
        id: res.id,
        stuName: res.stuName,
        stuNo: res.stuNo,
        collNo: res.collNo,
        sqecCode: res.sqecCode,
        classNo: res.classNo,
        collName: res.collName,
        sqecName: res.sqecName,
        className: res.className
      }
      commonOption.getClassInfoBySpecCode(res.sqecCode).then((classes) => {
        this.classInfos = classes
      })
    })
  },
  methods: {
    toSave() {
      this.loading = true
      settingClassInfo(this.form)
        .then((res) => {
          this.$notify({
            title: '班级配置成功',
            type: 'success',
            duration: 2500,
          })
          this.toBack()
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    toBack() {
      this.form = {
        id: null,
        stuName: null,
        stuNo: null,
        collNo: null,
        sqecCode: null,
        classNo: null,
        collName: null,
        sqecName: null,
        className: null
      }
      this.$router.go(-1);
    },
    doSubmit() {
      // 提交
      this.$refs.form.validate().then(
        (res) => {
          if (validCustom(this.customRuleFileds, this)) {
            // 验证通过数据提交
            Dialog.confirm({
              title: "提示",
              message: "你确定要进行提交操作吗？",
            })
              .then(() => {
                this.toSave(this.form);
              })
              .catch(() => {
              });
          } else {
            this.$notify({
              type: "warning",
              message: "有提交项未填写！",
              duration: 1500,
            });
          }
        },
        (err) => {
          validCustom(this.customRuleFileds, this);
          this.$notify({
            type: "warning",
            message: "有提交项未填写！",
            duration: 1500,
          });
        }
      );
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .van-field__label {
  width: 110px;
}
</style>